@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  font-family: "Poppins";
  scroll-behavior: smooth;
}

.scrollContainer::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollContainer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollContainer::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 500px;
}

/* Handle on hover */
.scrollContainer::-webkit-scrollbar-thumb:hover {
  background: #bdbbbb;
}

.scrollContainerForSidebar::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollContainerForSidebar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollContainerForSidebar::-webkit-scrollbar-thumb {
  background: #4f6cc4;
  border-radius: 500px;
}

/* Handle on hover */
.scrollContainerForSidebar::-webkit-scrollbar-thumb:hover {
  background: #4f6cc4;
}
